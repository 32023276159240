
import Vue, { PropType } from 'vue'
import SortSeriesElement, {
  SeriesSortOptions,
} from '~/components/sort/SeriesElement.vue'
import ListHeaderWrapper, {
  ListHeaderWrapperProps,
} from '~/components/_general/ListHeaderWrapper.vue'
import FilterSeriesElement from '~/components/filter/SeriesElement.vue'

export default Vue.extend({
  name: 'SiteSeriesListHeader',
  components: {
    FilterSeriesElement,
    ListHeaderWrapper,
    SortSeriesElement,
  },
  props: {
    ...ListHeaderWrapperProps,
    sort: {
      type: String as PropType<SeriesSortOptions>,
      default: SeriesSortOptions.Newest,
    },
  },
  methods: {
    setSearch(search: string) {
      this.$emit('search', search)
    },
    sortChanged(sort: SeriesSortOptions) {
      this.$emit('sort', sort)
    },
  },
})
