
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { Sermon } from '~/models/sermon'
import { SermonDisplay, SermonMetadataExtendedInfo } from '~/assets/ts/enums'
import { getListWithPlaceholders } from '~/assets/ts/utils/lists'
import SermonElement from '~/components/_general/SermonElement.vue'

export const SiteSermonListProps = {
  sermons: {
    type: Array as PropType<Sermon[]>,
    default: () => {
      return []
    },
  },
  listKey: {
    type: String as PropType<TranslateResult | string>,
    required: true,
  },
  loaded: {
    type: Boolean,
    default: true,
  },
  small: {
    type: Boolean,
  },
  maxExtendedInfo: {
    type: Number as PropType<SermonMetadataExtendedInfo>,
    default: SermonMetadataExtendedInfo.Broadcaster,
  },
  placeholderCount: {
    type: Number,
    default: 3,
  },
  display: {
    type: Number,
    default: SermonDisplay.Extended,
  },
  hideDescription: {
    type: Boolean,
  },
}

export default Vue.extend({
  name: 'SiteSermonList',
  components: { SermonElement },
  inheritAttrs: false,
  props: SiteSermonListProps,
  computed: {
    sermonList(): Sermon[] {
      if (this.loaded && !this.sermons.length) {
        return []
      } else {
        return getListWithPlaceholders(this.sermons, this.placeholderCount)
      }
    },
  },
})
