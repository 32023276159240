var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative overflow-hidden dark:text-white mb-4 container-height",on:{"mousemove":_vm.pauseTimer,"mouseleave":_vm.unpauseTimer}},[_c('Poller',{attrs:{"poll-on-activate":false,"update-ms":_vm.changeFrequency,"active":!_vm.paused},on:{"poll":_vm.setNextGraphic}}),_vm._v(" "),_vm._l((_vm.items),function(item,index){return _c('div',{key:_vm.key(index, 'hero'),staticClass:"transition-opacity absolute-fill bg-gray-100 dark:bg-black",class:[
      index === _vm.active ? 'opacity-100 z-10' : 'opacity-0 pointer-events-none',
      _vm.transitionDuration,
    ]},[(index === _vm.active && item?.campaignID && _vm.mounted)?_c('SiteAdTrackerImage',{staticClass:"absolute",attrs:{"campaign-i-d":item.campaignID}}):_vm._e(),_vm._v(" "),_c('BackgroundImage',{staticClass:"blur-xl absolute -top-10 -left-10 -right-10 -bottom-10 light:saturate-150 light:brightness-105 opacity-50",attrs:{"src":_vm.heroGraphicUrl(item)}}),_vm._v(" "),_c('SiteLayoutWithSideContent',{staticClass:"relative",attrs:{"collapsed-gap":false,"bottom-padding":false,"top-padding":false},scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('div',{staticClass:"-mx-6 sm:-mx-10 hero-height relative"},[_c('button',{staticClass:"arrow-button left-0",attrs:{"type":"button"},on:{"click":_vm.setLastGraphic}},[_c('SaIcon',{attrs:{"name":"angle-left"}})],1),_vm._v(" "),_c('button',{staticClass:"arrow-button right-0",attrs:{"type":"button"},on:{"click":_vm.setNextGraphic}},[_c('SaIcon',{attrs:{"name":"angle-right"}})],1),_vm._v(" "),_c('div',{staticClass:"absolute-fill loading-placeholder !rounded-none"}),_vm._v(" "),_c('BackgroundImage',{staticClass:"hero-height relative",attrs:{"src":_vm.heroGraphicUrl(item)}}),_vm._v(" "),(_vm.items.length > 1)?_c('div',{staticClass:"absolute text-center left-0 right-0 bottom-4 space-x-2"},_vm._l((_vm.items),function(_i,buttonIndex){return _c('button',{key:_vm.key(index, 'button', buttonIndex),staticClass:"inline-block h-3 w-3 border border-white rounded-full button-shadow transition-colors",class:[
                buttonIndex === _vm.active ? 'bg-white' : 'bg-black/20',
                _vm.transitionDuration,
              ],on:{"click":function($event){return _vm.switchTo(buttonIndex)}}})}),0):_vm._e()],1)]},proxy:true},{key:"side",fn:function(){return [_c('SiteHomeHeroSide',{staticClass:"transition-opacity",class:[
            index === _vm.active ? `opacity-100` : 'opacity-0',
            _vm.textTransitionDurationClass,
          ],attrs:{"title":item?.title,"text":item?.description,"action-url":_vm.actionUrl(item),"action-to":_vm.actionTo(item),"action-text":item?.actionText},on:{"buttonClicked":_vm.heroItemClicked}})]},proxy:true}],null,true)})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }