
import Vue from 'vue'
import SearchInput from '~/components/_general/SearchInput.vue'
import { qsValue } from '~/assets/ts/utils/params'

export function GetSeriesSearchFromQs(context: Vue): string {
  const qsSearch = qsValue(context, 'q')
  return qsSearch ?? ''
}

export default Vue.extend({
  name: 'FilterSeriesElement',
  components: { SearchInput },
  props: {
    disableSearch: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchQuery: GetSeriesSearchFromQs(this),
    }
  },
  computed: {
    searchMinLength(): number {
      return 3
    },
  },
  methods: {
    search() {
      this.$emit('search', this.searchQuery)
    },
  },
})
