
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { waitOneFrame } from '~/assets/ts/utils/misc'
import SaLink from '~/components/_general/SaLink.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import SaLinkOrHref from '~/components/_general/SaLinkOrHref.vue'

export default Vue.extend({
  name: 'SiteHomeHeroSide',
  components: { SaLinkOrHref, MarkdownElement },
  props: {
    darkOnly: {
      type: Boolean,
    },
    fixedHeight: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    actionUrl: {
      type: String,
      default: undefined,
    },
    actionTo: {
      type: String,
      default: undefined,
    },
    actionText: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
  },
  data() {
    return {
      lineClampCount: 1,
      resizeTimeout: 0,
      updatingLineCount: true,
    }
  },
  computed: {
    SaLink() {
      return SaLink
    },
  },
  mounted() {
    window.addEventListener('resize', this.resize)
    if (!this.fixedHeight) return
    this.updateDescriptionHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      window.clearTimeout(this.resizeTimeout)
      this.resizeTimeout = window.setTimeout(() => {
        this.afterTimeout()
      }, 150)
    },
    async afterTimeout() {
      this.updatingLineCount = true
      await waitOneFrame()
      this.updateDescriptionHeight()
    },
    updateDescriptionHeight() {
      const desc = this.$refs.description as HTMLElement
      const height = desc.clientHeight
      this.lineClampCount = Math.floor(height / 22)
      this.updatingLineCount = false
    },
  },
})
