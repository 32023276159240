
import Vue from 'vue'
import SiteSermonList, { SiteSermonListProps } from './SermonList.vue'
import SiteTitledSection, { SiteTitledSectionProps } from './TitledSection.vue'

export default Vue.extend({
  name: 'SiteSermonListWithTitle',
  components: { SiteSermonList, SiteTitledSection },
  inheritAttrs: false,
  props: {
    ...SiteTitledSectionProps,
    ...SiteSermonListProps,
    listKey: {
      type: String,
      default: '',
    },
  },
})
