
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { EventTypeApi } from '~/apiclient/apifilters'
import { SermonRequestOptions } from '~/apiclient/apisermons'
import { SermonDisplay } from '~/assets/ts/enums'
import { sermonRootUrl } from '~/assets/ts/utils/urls'
import SaIcon from '~/components/_general/SaIcon.vue'
import SaLink from '~/components/_general/SaLink.vue'
import SiteFilteredSermonList from '~/components/site/FilteredSermonList.vue'
import { SaIconsType } from '~/assets/ts/types/icons'

export default Vue.extend({
  name: 'SiteSermonsCategoryElement',
  components: { SiteFilteredSermonList, SaLink, SaIcon },
  props: {
    event: {
      type: Object as PropType<EventTypeApi>,
      required: true,
    },
    videoPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SermonDisplay,
    }
  },
  computed: {
    options(): SermonRequestOptions {
      const description = this.event.description
      return {
        pageSize: 3,
        requireVideo: true,
        eventType: description,
      } as SermonRequestOptions
    },
    eventIcon(): SaIconsType {
      switch (this.event.eventImage) {
        case 'audio_book.png':
          return 'audio_book-categories'
        case 'bible_study.png':
          return 'bible_study-categories'
        case 'campground.png':
          return 'campground-categories'
        case 'podium.png':
          return 'podium-categories'
        case 'smile.png':
          return 'smile-categories'
        case 'cassette.png':
          return 'cassette-categories'
        case 'users.png':
          return 'users-categories'
        case 'newspaper.png':
          return 'newspaper-categories'
        case 'debate.png':
          return 'debate-categories'
        case 'coffee.png':
          return 'coffee-categories'
        case 'flower_tulip.png':
          return 'flower_tulip-categories'
        case 'calendar.png':
          return 'calendar-categories'
        case 'podcast.png':
          return 'podcast-categories'
        case 'praying_hands.png':
          return 'praying_hands-categories'
        case 'comment_question.png':
          return 'comment_question-categories'
        case 'comments.png':
          return 'comments-categories'
        case 'radio_event.png':
          return 'radio_event-categories'
        case 'cut.png':
          return 'cut-categories'
        case 'star.png':
          return 'star-categories'
        case 'sunrise.png':
          return 'sunrise-categories'
        case 'sunset.png':
          return 'sunset-categories'
        case 'sun.png':
          return 'sun-categories'
        case 'chalkboard_teacher.png':
          return 'chalkboard_teacher-categories'
        case 'church.png':
          return 'church-categories'
        case 'graduation_cap.png':
          return 'graduation_cap-categories'
        case 'user_chat.png':
          return 'user_chat-categories'
        case 'tv.png':
          return 'tv-categories'
        case 'disc_drive.png':
          return 'disc_drive-categories'
        case 'rings_wedding.png':
          return 'rings_wedding-categories'
        case 'child.png':
          return 'child-categories'
        default:
          console.error(
            `No icon available for event category: ${this.event.description}`
          )
          return 'error'
      }
    },
    sermonCount(): TranslateResult {
      return this.$tc('{n} Sermon | {n} Sermons', this.event.numberOfSermons, {
        n: this.event.numberOfSermons.toLocaleString(),
      })
    },
    categoryUrl(): string {
      return `${sermonRootUrl}/categories/${this.event.description}`
    },
  },
})
