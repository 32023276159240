
import Vue from 'vue'
import SiteFilteredSeriesList, {
  SiteSeriesFilteredListProps,
} from '../FilteredSeriesList.vue'
import SiteTitledSection, {
  SiteTitledSectionProps,
} from '~/components/site/TitledSection.vue'

export default Vue.extend({
  name: 'SiteSeriesFilteredListWithTitle',
  components: { SiteFilteredSeriesList, SiteTitledSection },
  props: {
    ...SiteTitledSectionProps,
    ...SiteSeriesFilteredListProps,
    topBorder: {
      type: Boolean,
      default: false,
    },
    addMargin: {
      type: Boolean,
      default: false,
    },
  },
})
