
import Vue from 'vue'
import ViewportObserver from '~/components/_general/ViewportObserver .vue'

export default Vue.extend({
  name: 'DelayRender',
  components: { ViewportObserver },
  props: {
    onMount: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
  },
})
