import { render, staticRenderFns } from "./ViewportObserver .vue?vue&type=template&id=a00ce9b6&scoped=true&"
import script from "./ViewportObserver .vue?vue&type=script&lang=ts&"
export * from "./ViewportObserver .vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a00ce9b6",
  null
  
)

export default component.exports